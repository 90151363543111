import { render, staticRenderFns } from "./medicalRecord.vue?vue&type=template&id=3940d9e4&scoped=true"
import script from "./medicalRecord.vue?vue&type=script&lang=js"
export * from "./medicalRecord.vue?vue&type=script&lang=js"
import style0 from "./medicalRecord.vue?vue&type=style&index=0&id=3940d9e4&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3940d9e4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/wxq/workspace/ssl-odat-patient-wechat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3940d9e4')) {
      api.createRecord('3940d9e4', component.options)
    } else {
      api.reload('3940d9e4', component.options)
    }
    module.hot.accept("./medicalRecord.vue?vue&type=template&id=3940d9e4&scoped=true", function () {
      api.rerender('3940d9e4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/patient/medicalRecord.vue"
export default component.exports