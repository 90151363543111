var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "msg-content" },
    [
      _c("div", { staticClass: "content-top" }, [
        _vm.detailData.status === 1 ? _c("i") : _vm._e(),
        _c("span", { staticClass: "status" }, [
          _vm._v(
            _vm._s(
              _vm.detailData.status === 1
                ? "问诊中"
                : _vm.detailData.status === 0
                ? "已结束"
                : ""
            )
          ),
        ]),
        _vm.detailData.type === 12 && _vm.detailData.status === 1
          ? _c("span", [
              _vm._v(
                "第" +
                  _vm._s(_vm.detailData.rounds) +
                  "/" +
                  _vm._s(_vm.detailData.totalRounds) +
                  "次"
              ),
            ])
          : _vm._e(),
        _vm.detailData.type === 11 && _vm.detailData.status === 1
          ? _c("span", [_vm._v("倒计时：" + _vm._s(_vm.timeStr))])
          : _vm._e(),
      ]),
      _c(
        "van-pull-refresh",
        {
          ref: "msgList",
          staticClass: "msg-list",
          attrs: {
            "loosing-text": this.finished ? "已无更多数据" : "下拉加载更多",
            "pulling-text": "下拉加载更多",
          },
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function ($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing",
          },
        },
        [
          _c(
            "van-list",
            {
              ref: "msgList",
              staticClass: "msg-list",
              attrs: {
                finished: _vm.finished,
                "finished-text": "请您耐心等待，接诊后，您可与医生直接沟通",
                offset: "0",
                direction: "up",
                "immediate-check": false,
              },
            },
            _vm._l(_vm.dataList, function (item, index) {
              return _c("div", { key: index, staticClass: "msg-item" }, [
                _c("div", { staticClass: "time" }, [
                  _c("span", [_vm._v(_vm._s(_vm.formatDate(item.createdAt)))]),
                ]),
                _vm.userInfo.mobilePhone === item.from ||
                item.from === "ODAT-PATIENT-" + _vm.userInfo.mobilePhone
                  ? _c("div", { staticClass: "my-msg item-content" }, [
                      item.MsgContent.MsgType === "TIMCustomElem" &&
                      item.MsgContent.MsgContent.type === "Consultation"
                        ? _c(
                            "div",
                            {
                              staticClass: "consultation",
                              on: {
                                click: function ($event) {
                                  return _vm.handleConsultation(item)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "consultation-top" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      item.MsgContent.MsgContent.data
                                        .patientName
                                    )
                                  ),
                                ]),
                                _c("i"),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      item.MsgContent.MsgContent.data
                                        .patientGender === 1
                                        ? "男"
                                        : "女"
                                    )
                                  ),
                                ]),
                                _c("i"),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      item.MsgContent.MsgContent.data.patientAge
                                    ) + "岁"
                                  ),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "consultation-bottom" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "consultation-bottom-center",
                                    },
                                    [
                                      _c("span", [_vm._v("病情描述：")]),
                                      _c("div", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.cutdownStr(
                                                item.MsgContent.MsgContent.data
                                                  .diseaseDescription,
                                                20
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "prescription-bottom" },
                                    [
                                      _c("span", [_vm._v("查看问诊详情")]),
                                      _c("i"),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : item.MsgContent.MsgType === "TIMImageElem"
                        ? _c(
                            "div",
                            { staticClass: "message-content img-content" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "img-content",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handelClickImg(
                                        item.MsgContent.MsgContent.Image ||
                                          item.MsgContent.MsgContent.Url
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        item.MsgContent.MsgContent.Image ||
                                        item.MsgContent.MsgContent.Url +
                                          `?x-oss-process=image/resize,w_100`,
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : item.MsgContent.MsgType === "TIMTextElem"
                        ? _c("div", { staticClass: "message-content" }, [
                            _c("div", [
                              _vm._v(_vm._s(item.MsgContent.MsgContent.Text)),
                            ]),
                          ])
                        : _vm._e(),
                      _c("i", { staticClass: "avatar my-i" }),
                    ])
                  : _c("div", { staticClass: "other-msg item-content" }, [
                      _c("i", { staticClass: "avatar doctor-i" }),
                      item.MsgContent.MsgType === "TIMCustomElem" &&
                      item.MsgContent.MsgContent.type === "Prescription"
                        ? _c(
                            "div",
                            {
                              staticClass: "prescription",
                              on: {
                                click: function ($event) {
                                  return _vm.handlePrescription(
                                    item.MsgContent.MsgContent.data
                                      .prescriptionId
                                  )
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "prescription-top" }, [
                                _c("i", { staticClass: "disease-icon" }),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.cutdownStr(
                                        item.MsgContent.MsgContent.data.title,
                                        10
                                      )
                                    )
                                  ),
                                ]),
                                +item.MsgContent.MsgContent.data.status === 1
                                  ? _c("i", { staticClass: "status-icon-1" })
                                  : _vm._e(),
                                +item.MsgContent.MsgContent.data.status === 2
                                  ? _c("i", { staticClass: "status-icon-2" })
                                  : _vm._e(),
                                +item.MsgContent.MsgContent.data.status === 3
                                  ? _c("i", { staticClass: "status-icon-3" })
                                  : _vm._e(),
                                +item.MsgContent.MsgContent.data.status === 4
                                  ? _c("i", { staticClass: "status-icon-4" })
                                  : _vm._e(),
                                +item.MsgContent.MsgContent.data.status === 5
                                  ? _c("i", { staticClass: "status-icon-5" })
                                  : _vm._e(),
                                +item.MsgContent.MsgContent.data.status === 6
                                  ? _c("i", { staticClass: "status-icon-6" })
                                  : _vm._e(),
                              ]),
                              _c(
                                "div",
                                { staticClass: "prescription-bottom" },
                                [_c("span", [_vm._v("查看处方详情")]), _c("i")]
                              ),
                            ]
                          )
                        : item.MsgContent.MsgType === "TIMTextElem"
                        ? _c("div", { staticClass: "message-content" }, [
                            _c("div", [
                              _vm._v(_vm._s(item.MsgContent.MsgContent.Text)),
                            ]),
                          ])
                        : item.MsgContent.MsgType === "TIMImageElem"
                        ? _c(
                            "div",
                            { staticClass: "message-content img-content" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "img-content",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handelClickImg(
                                        item.MsgContent.MsgContent.Image ||
                                          item.MsgContent.MsgContent.Url
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        item.MsgContent.MsgContent.Image ||
                                        item.MsgContent.MsgContent.Url +
                                          `?x-oss-process=image/resize,w_100` ||
                                        item.MsgContent.MsgContent.Url,
                                    },
                                    on: { error: _vm.onError },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : item.MsgContent.MsgType === "TIMSoundElem"
                        ? _c(
                            "div",
                            { staticClass: "message-content sound-content" },
                            [
                              _c("audio", {
                                attrs: {
                                  controls: "",
                                  controlsList: "nodownload",
                                  src: item.MsgContent.MsgContent.Url,
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]),
              ])
            }),
            0
          ),
        ],
        1
      ),
      _c("van-image-preview", {
        attrs: { images: _vm.imageList },
        on: { change: _vm.onChangePreview },
        scopedSlots: _vm._u([
          {
            key: "imgIndex",
            fn: function () {
              return [_vm._v("第" + _vm._s(_vm.imgIndex + 1) + "页")]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.showImgPopup,
          callback: function ($$v) {
            _vm.showImgPopup = $$v
          },
          expression: "showImgPopup",
        },
      }),
      _vm.detailData.status === 1
        ? _c(
            "div",
            { staticClass: "page-bottom" },
            [
              _c(
                "div",
                { staticClass: "img-uploader" },
                [
                  _c("i"),
                  _c("van-uploader", {
                    staticClass: "pic-uploader",
                    attrs: {
                      "before-read": _vm.beforeRead,
                      "after-read": _vm.afterRead,
                      accept: "image/*",
                      "max-count": 1,
                    },
                  }),
                ],
                1
              ),
              _c("van-field", {
                staticClass: "text-input",
                attrs: {
                  type: "textarea",
                  rows: "1",
                  autosize: { maxHeight: 70 },
                  placeholder: "病情描述，一次最多输入200字",
                },
                on: { input: _vm.onTextChange },
                model: {
                  value: _vm.text,
                  callback: function ($$v) {
                    _vm.text = $$v
                  },
                  expression: "text",
                },
              }),
              _c(
                "van-button",
                {
                  staticClass: "send-btn",
                  attrs: { disabled: _vm.btnDisabled || !_vm.text },
                  on: { click: _vm.handleSendText },
                },
                [_vm._v("发送")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }